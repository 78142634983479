<template>
    <div style="height:100vh;background:white">   
        <div  style=" display:flex">  
            <div style="min-width:550px;width:550px">                             
                <Card >
                    <span slot="title">
                        <i class="iconfont icon-gongzuotai" style="color:#409eff;font-size:14px;margin-right:6px;"></i>
                        <i class="web-font" style="font-size:14px; font-weight:500;color:#409EFF">我的常用表单</i> 
                        <el-input clearable  v-model="v_formnm" size="mini" placeholder="快速查找常用表单" style="margin-left:10px;width:150px;height:15px;margin-top:-10px"></el-input>
                        <el-link :underline="false" @click="freshLove" style="float:right;">
                            <el-tooltip content="刷新常用表单" effect="light">
                                <i class="iconfont icon-shuaxin1" style="float:right;color:#409eff;font-size:14px;margin-right:4px;"></i>
                            </el-tooltip>  
                        </el-link>                       
                    </span>
                    <div :style="{width: '100%',height:halfHigh , 'overflow-y':'auto','overflow-x':'hidden' }"  >
                        <div style="display:flex;flex-wrap:wrap;  ">
                            <span v-for="(item,index) in loveItem" :key="index">
                                <div class="line-img">
                                    <div style="text-align: center">
                                        <el-link  :underline="false"   @click ="topClick(item.FRMURL )">
                                            <img src="@/assets/mylove.png" alt="" width="36" height="36" />
                                        </el-link>
                                    </div>
                                    <div style="height:5px;"></div>
                                    <span style="text-align: center;font-size:10px "  >
                                        <el-link  :underline="false"   @click ="topClick(item.FRMURL )"><span style="font-size:11px"> {{item.FRMNM}}</span></el-link>
                                    </span>
                                </div>  
                            </span>
                        </div>
                    </div>
                </Card> 
                <!-- 第二行 -->
                <Card style="margin-top:10px" >                         
                    <span slot="title">
                        <i class="iconfont icon-kaoqinguanli1" style="color:#409eff;font-size:14px;margin-right:6px;"></i>
                        <i class="web-font" style="font-size:14px; font-weight:500;color:#409EFF">我的工作日历事项</i>
                        <el-date-picker :clearable="false" v-model="v_mth" size="mini" style="width:120px;height:15px;margin-left:15px;margin-top:-5px" @change="changeDate" type="month" value-format="yyyy-MM" placeholder="选择月份"></el-date-picker>
                        <el-link :underline="false" @click="dayList" style="float:right;">
                            <el-tooltip content="日历事项明细" effect="light">
                                <i class="iconfont icon-gengduo" style="float:right;color:#409eff;font-size:14px;margin-right:4px;"></i>
                            </el-tooltip>
                        </el-link>
                    </span>
                    <div    :style="{width: '100%', height:halfHigh ,'min-height':'300px' }">
                        <div :style="{width: '100%', 'overflow-y':'auto','overflow-x':'hidden'}">
                            <calendar :height="supHeight" ref="refcal" style="margin-top:-14px; "
                                        :show-title="false"
                                        @date-click="dateClick"
                                        :show-festival="false"
                                        :show-term="false"
                                        :select-date="selDate" 
                                        :default-date="defaultDate">
                                <template slot="dateCell" slot-scope="{date}">
                                    <span slot="reference" class="obj">
                                        <span v-if="rmkArr.filter(item=>item['DTTM']==`${date.year+'-'+date.month+'-'+date.day}` ).length>0">
                                            <i class="iconfont icon-yuandianxiao" style="color:red"> </i> 
                                        </span>
                                    </span>
                                </template>
                            </calendar>
                        </div>
                    </div>
                </Card> 
            </div>
            <div :style="{'width':taskWidth,'margin-left':'15px'}">
                <div style="margin-top:1px;line-height:28px;height:28px;background:#ccc;border-left:5px solid #409eff">
                    <span style="margin-left:10px;color:black">工作跟进</span>
                </div>
                <div style="display:flex;min-width:650px; margin-top:5px">
                     <div style="width:200px;height:80px;background: #5cadef;display:flex">
                         <div style="display:flex;flex-direction:column;width:100px">
                             <div style="font-weight:600;font-size:14px;color:white;height:50px;line-height:50px;margin-left:20px">待我确认</div>
                             <div style="font-weight:600;font-size:16px;color:white;height:30px;line-height:20px; margin-left:20px">0</div>
                         </div>
                         <div style="line-height:80px;width:80px;margin-left:20px">
                            <i class="iconfont icon-shenhezhong1" style="font-size:64px;color:white" ></i>
                         </div>
                     </div>
                     <div style="width:200px;height:80px;background: #669998;display:flex;margin-left:15px">
                         <div style="display:flex;flex-direction:column;width:100px">
                             <div style="font-weight:600;font-size:14px;color:white;height:50px;line-height:50px;margin-left:20px">待我审批</div>
                             <div style="font-weight:600;font-size:16px;color:white;height:30px;line-height:20px; margin-left:20px">0</div>
                         </div>
                         <div style="line-height:80px;width:80px;margin-left:20px">
                            <i class="iconfont icon-shenhe" style="font-size:64px;color:white" ></i>
                         </div>
                     </div>
                     <div style="width:200px;height:80px;background: #808695;display:flex;margin-left:15px">
                         <div style="display:flex;flex-direction:column;width:100px">
                             <div style="font-weight:600;font-size:14px;color:white;height:50px;line-height:50px;margin-left:20px">我发起的</div>
                             <div style="font-weight:600;font-size:16px;color:white;height:30px;line-height:20px; margin-left:20px">0</div>
                         </div>
                         <div style="line-height:80px;width:80px;margin-left:20px">
                            <i class="iconfont icon-faqi" style="font-size:64px;color:white" ></i>
                         </div>
                     </div>
                 </div>  
                 <div id="taskid" >
                 <el-table  :data="taskData" 
                    border                            
                    disabled-hover
                    :row-key="getRowKey"          
                    style="overflow:auto;margin-top:3px;"
                    ref="reftask"
                    :height="taskHeight" 
                    highlight-current-row>
                    <el-table-column  v-for="(item,index ) in taskCol.filter(item=>item.thide=='N')" :key="index"
                            :prop="item.key"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            :sortable="item.key!=='RN'"
                            :min-width="item.width">
                        <template slot-scope="scope">
                            <span v-text ="scope.row[item.key]"></span>
                        </template>
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="80">
                        <template slot-scope="scope">
                            <i class="iconfont icon-shenhe" style="color:#409EFF;font-size:14px;margin-right:5px;"></i>
                            <el-link   :underline="false" @click="cfmRec(scope.row['IDSEQ'])" ><span style="font-size:11px;">审核 </span></el-link>     
                        </template>
                    </el-table-column>                         
                </el-table>
                </div>
                <div style="padding-top:5px;  font-size:12px;line-height:30px;background:#fff;height:35px;border-top:2px solid #ccc;">    
                    <span style="float: left;padding-left:4px">
                        <Page :total="dataCount" :page-size="pageSize" show-total :current.sync="current" @on-change="changePage" ></Page>
                    </span>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="promptWin" width="450px" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div> 
            <span>{{prompt}}</span>
            <span slot="footer"  >
                <el-button type="primary" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 工作日历明细 -->
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="calWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="2vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;"> 工作日历明细 </span>
            </div> 
            <div style=" display:flex; width:100%; background:#fff;line-height:30px;height:38px;border-bottom:2px solid #389;padding-top:3px">   
                <el-select v-model="selparam"  placeholder="请选择列名" clearable size="small" style="width:150px; ">
                    <span v-for="item in tabArr" :key='item.key' >
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input clearable ref="refinput" :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="qryTabData" size="small" style="width:320px; height:30px; ">
                    <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO" ></el-option>
                        </span>
                    </el-select>
                    <el-button size="small" slot="append" icon="el-icon-search" @click="qryTabData" ></el-button>
                </el-input>
                <el-select v-model="selparam2"  placeholder="请选择列名" clearable size="small" style="width:150px;margin-left:20px; ">
                    <span v-for="item in tabArr" :key='item.key'>
                        <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                    </span>
                </el-select>
                <el-input clearable :placeholder="input_hold" v-model="txtparam2" @keyup.enter.native="qryTabData" size="small" style="width:320px; height:30px; ">
                    <el-select v-model="oper2" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"  ></el-option>
                        </span>
                    </el-select>
                    <el-button size="small" slot="append" icon="el-icon-search" @click="qryTabData" ></el-button>
                </el-input>
            </div> 
            <el-table  :data="tabData" 
                    border                            
                    disabled-hover
                    style="overflow:auto;margin-top:1px;"
                    size="small"
                    :height="tabHeight" 
 
                    ref="tabref" 
                    highlight-current-row>
                <el-table-column  v-for="(item,index ) in tabArr.filter(item=>item.thide=='N')" :key="index"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    :sortable="item.key!=='RN'&&item.edcss!=='checkbox'"
                    :min-width="item.width">
                    <template  slot-scope="scope" >                       
                        <div  v-text="scope.row[item.key]" style="float:left"> </div>
                    </template>
                </el-table-column> 
            </el-table>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="calWin=false"  >退出</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 日历事项 -->
        <el-dialog :show-close="false" :visible.sync="dayWin" width="750px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;"> 工作日历  </span>
            </div> 
            <div style="line-height:30px;height:30px;font-size:11px">工作日期</div>
            <el-input v-model="v_dttm"  readonly></el-input>
            <div style="line-height:30px;height:30px;font-size:11px">工作内容</div>
            <el-input type="textarea" :rows="10" v-model="v_rmk" placeholder="请输入内容" ></el-input>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px;  ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="dayWin=false" style="margin-right:50px;">退出</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun"  @click="saveDay" style="margin-right:10px;">保存</el-button>         
                </span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
   
    import {getBaseData,getTabColOrData } from '../api/user'
    import {getDate} from '../api/Select'
    import 'vue-lunar-calendar-pro/dist/calendar.css';
    import Calendar from 'vue-lunar-calendar-pro'
    export default {
        name: "mainPage",
        components: {
            Calendar,
       
        },
        mounted () {
 
            if (this.$cookies.get('v_username') ){      
          
                // 用户最近访问表单
                this.freshLove()
     
            } else{
                let routeUrl = this.$router.resolve({
                    path: "/",
                    query: { },
                });
                window.open(routeUrl.href, '_self');
            }
        },

        data(){
            return {
                tabArr:[],
                tabData:[],
                calWin:false,
                selparam:'',
                txtparam:'',
                oper:'',
                selparam2:'',
                txtparam2:'',
                oper2:'',
                operArr:[],
                v_formnm:'',
                dayWin:false,
                defaultDate:'', //指定显示当前的月份
                promptWin:false,
                prompt:'',
                saleAll:[],
                bomAll:[],
                bomArr:[],
                saleArr:[],
                input_hold:'请输入内容查询',
                prcArr:[],
                dtlData:[],
                dtlCol:[],
                selDate:[],
                supArr:[],
                supNum:0,
                v_cond:'',
                bomnm:'',
                cltnm:'',
                adddttm:'',
                dataCount:0,
                prcWin:false,
                v_url:this.$store.state.queryUrl , //api请求路径   
                v_username :this.$cookies.get('v_username' ), //用户名称 
                v_password:this.$cookies.get('v_password') ,//api 请求密码
                saleCount:0,
                pageSize:50, 
                rowSize:7,
                current:1,
                bomWin:false,
                prcCount:0,
                prcAll:[],
                year:'',
                bomSeq:'',
                v_askcond:'',
                supAll:[],
                // 柱形图
                mthData:[],
                chartInstance:{}   ,
                v_mth:'',                
                loveItem:[],
                v_dttm:'',
                v_rmk:'',
                v_idseq:'',
                taskCol:[],
                taskData:[],
                rmkArr:[],
            }
        },
        created () {
            this.defaultDate=getDate()
            this.selDate =[getDate()]
            this.v_mth =getDate('month')
            this.initCal() //初始化日历数据
            this.pushBom()
  
            this.getTabCol()
            //this.getPrcList()
        },
        watch:{
            v_formnm  : function(){
                this.formQry()
            }
        },
        computed: {
            tabHeight: function() {
                return (window.innerHeight - 170) + 'px';
            }, 
            tabWidth: function() {
                return (window.innerWidth - 160) + 'px';
            }, 
            taskHeight: function() {
                return (window.innerHeight - 240) + 'px';
            }, 
            taskWidth: function() {
                return (window.innerWidth - 630) + 'px';
            }, 
            supHeight: function() {
                return (window.innerHeight /2-100  ) + 'px';
            }, 
            halfHigh: function() {
                return (window.innerHeight /2 - 125) + 'px';
            }, 
        },
        methods:{
            //查询
            qryTabData(){
                let cond_=''
                if (this.selparam && this.txtparam){ //列1
                    if (this.oper==='like'){
                        cond_=cond_+' and upper('+this.selparam+') like upper(\'%' + this.txtparam+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam+')' +this.oper+'upper(\''+this.txtparam+'\')'
                    }
                }
                if (this.selparam2 && this.txtparam2){ //列1
                    if (this.oper2==='like'){
                        cond_=cond_+' and upper('+this.selparam2+') like upper(\'%' + this.txtparam2+'%\')'
                    }else{
                        cond_=cond_+' and upper('+this.selparam2+')' +this.oper2+'upper(\''+this.txtparam2+'\')'
                    }
                }
                getTabColOrData(this.v_url,this.v_username,this.v_password,'V_hr_msg','userno',this.v_username,'','','ty','WORK',cond_).then((res) => {
                    this.tabData =res.data.result          
                })
            },
            changePage(){

            },
            dayList(){

            },
            getRowKey(row){
                return row['IDSEQ']
            },
            formQry(){
                let v_cond =' and lower(frmnm) like \'%'+this.v_formnm.toLowerCase()+'%\''
                getBaseData(this.$store.state.nologinUrl, 'userno',this.v_username,'','','v_userlog',v_cond +' order by logdttm desc').then(res=>{
                    this.loveItem=res.data.result
                }) 
            },
            initCal(){
                this.rmkArr=[]
                getTabColOrData(this.v_url,this.v_username,this.v_password,'hr_msg','userno',this.v_username,'', '','','',' and rmk is not null').then((res) => {
                    this.rmkArr =res.data          
                })
            },
 
            saveDay(){
                let v_obj ={'IDSEQ':this.v_idseq,'USERNO':this.v_username,'DTTM':this.v_dttm,'RMK':this.v_rmk}
                this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+ this.v_username+'&password='+ this.v_password,
                    data: {p_json:JSON.stringify(v_obj), p_table:'hr_msg',frmid:''},
                }).then( res=>{                
                    this.initCal()
                    this.dayWin =false
                })   
            },
            // 日期点击
            dateClick(date){
                this.dayWin =true
                this.v_dttm=date.year+'-'+date.month+'-'+date.day
                this.v_rmk =''
                this.v_idseq=''
                getTabColOrData(this.v_url,this.v_username,this.v_password,'hr_msg','userno',this.v_username,'dttm',this.v_dttm,'','','').then((res) => {
                    if( res.data.length>0){
                        this.v_rmk =res.data[0]['RMK']
                        this.v_idseq =res.data[0]['IDSEQ']
                    }                      
                })
            },
            //改变月份
            changeDate(val){
                let dateArr =val.split('-')
                this.$refs.refcal.render(dateArr[0], dateArr[1]);
            },
            //刷新常用表单
            freshLove(){
                getBaseData(this.$store.state.nologinUrl, 'userno',this.v_username,'','','v_userlog',' order by logdttm desc').then(res=>{
                    this.loveItem=res.data.result
                })
            },
            //常用表单点击事件
            topClick(val){     
                this.$router.push({
                    path: val,
                }, () => {})          
            },
            prcExit(){
                this.prcWin=false
                this.getPrcList()
            },
 
            //更新柱状图
            updTreeBar(){
                this.mthData=[]
                getTabColOrData(this.v_url,this.v_username,this.v_password,'V_SALECHARTS','','','yr',this.year,'','',' order by mth').then((res) => {
                    for(let k=0;k<res.data.length;k++){
                        this.mthData.push(res.data[k]['AMT'])
                    } 
                    this.updChart()                         
                })
            },
 
 
            updChart(){
                let option = {
                    color: ['#f44'],
                    grid:{
                        //与绝对定位相似，top，left，right，bottom 设定是根据上级盒子宽高来计算
                        top:"10px",
                        left:"40px",
                            right:"10px",
                            bottom:"20px"
                        },
                        tooltip : {
                            trigger: 'axis',
                            axisPointer : {
                                type : 'shadow'
                            }
                        },
                        xAxis : [
                            {
                                type : 'category',
                                data : ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月',],
                                axisTick: {
                                    alignWithLabel: true, // 图形于标题居中
                                }
                            }
                        ],
                        yAxis : [
                            {
                                type : 'value'
                            }
                        ],
                        series : [
                            {
                                name:'每月销售',
                                type:'bar',
                                barWidth: '60%',
                                data: this.mthData  
                            }
                        ]
                    };
                    this.chartInstance.setOption(option,true);
            } ,
 
            // 接收服务器推送的信息
            pushBom(){
                var that =this
                if("WebSocket" in window){
                    var ws =new WebSocket(this.$store.state.wsUrl+this.$cookies.get('v_username' ))
                    ws.onmessage =function(evt){
                        var receive_msg =evt.data
                        console.log('socket:' +receive_msg)
                        if (receive_msg=='bomSave'){ //客户询价单
                            //
                        }else if (receive_msg=='ordCfm'){ //前端订单购买确认
                           // that.updTreeBar()
                        }else if (receive_msg=='askPrc'){ //前端订单购买确认
                            that.getPrcList()
                        }
                    }
                }else{
                    this.$alert('你的浏览器不支持websocket', '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'warning'
                    });
                }
            },  
            supPage(index){
                var _start = ( index - 1 ) * this.rowSize;
                var _end = index * this.rowSize;
                this.supArr = this.supAll.slice(_start,_end);
            },
            //ask prc list
            getPrcList(){
                // 待跟进数据
                getBaseData(this.$store.state.nologinUrl, 'stfg','wait','','','v_prc_req',' order by adddttm desc').then(res=>{
                    if (this.v_username.substring(0,1)!=='S'){
                        this.prcAll=res.data.result
                        this.prcCount =this.prcAll.length //总条数
                        // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                        if (this.prcAll.length < this.rowSize){
                            this.prcArr = this.prcAll          
                        }else{
                            this.prcArr =this.prcAll.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
                        }
                    }
                }) 
            }, 
            prcPage(index){
                var _start = ( index - 1 ) * this.rowSize;
                var _end = index * this.rowSize;
                this.prcArr = this.prcAll.slice(_start,_end);
            },
            cfmPrc(idseq){
                this.v_askcond =' and idseq='+idseq
                this.prcWin=true
            },
 
            bomPage(index){
                var _start = ( index - 1 ) * this.rowSize;
                var _end = index * this.rowSize;
                this.bomArr = this.bomAll.slice(_start,_end);
            },
            salePage(index){
                var _start = ( index - 1 ) * this.rowSize;
                var _end = index * this.rowSize;
                this.saleArr = this.saleAll.slice(_start,_end);
            },
            //获取bom明细
            getBomDtl(idseq){
                this.dtlData=[]
                getTabColOrData(this.v_url,this.v_username ,this.v_password,'V_bomdtl','idseq',idseq,'','','','','' ).then((res) => { 
                    this.dtlData =res.data 
                    this.$refs['refdtl'].doLayout()
                })
            },
            //弹出 bom明细
            cfmBom(idseq,bomnm,cltnm,adddttm){
                this.bomWin =true
                this.bomnm=bomnm
                this.cltnm=cltnm
                this.adddttm=adddttm
                this.bomSeq =idseq
                this.getBomDtl(idseq)
            } ,
            getTabCol(){
                var vm=this
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','39090','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                    for (let k=0; k<res.data.length; k++){
                        vm.dtlCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,  
                            thide: res.data[k].THIDE, 
                            fhide: res.data[k].FHIDE,                                               
                            disabled: res.data[k].DISABLED, 
                            edcss: res.data[k].EDCSS, 
                            dotnum: res.data[k].DOTNUM, 
                        })
                    }
                })
                getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','56933','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                    for (let k=0; k<res.data.length; k++){
                        vm.taskCol.push({ //向数组的开头 添加序列号
                            title: res.data[k].title,
                            key: res.data[k].key,
                            align: res.data[k].align,
                            width: res.data[k].width,  
                            thide: res.data[k].THIDE, 
                            fhide: res.data[k].FHIDE,                                               
                            disabled: res.data[k].DISABLED, 
                            edcss: res.data[k].EDCSS, 
                            dotnum: res.data[k].DOTNUM, 
                        })
                    }
                })
            },
 
        }
    }
</script>

<style scoped lang="less">
    // 修改头部背景
    ::v-deep .el-table {
        th {
            padding: 0px ;
            background-color:#f7f8f9;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    } 
    .obj{
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 9;
        font-size: 12px;
    }
    .line-img{
        margin-right:4px;
        width:120px;
        height:90px;
        text-align:center;
        vertical-align:middle;
        display:inline-block;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
    .menuitem {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        padding-right:3px;
        height:34px;
        line-height:34px;
        display:flex;
    }
    .home-content-layout{
        background-color: #fff;
        width: 100%;
        height:96%;
        max-height: 96%;
        max-height:-webkit-calc(100% - 31px);
        max-height:-moz-calc(100% - 31px);
        max-height: calc(100% - 31px);  
    }
    .footer {
        width: 100%;
        position: absolute;
        bottom: 0 ;
        background:#f7F8F9;
        line-height:35px  !important;
        height:35px !important;
        vertical-align: center;
        text-align: center;
        color:#409EFF;
        font-weight: 600;
        font-size:12px;
    } 
    .item-width  {
        width: 32%;
        color:#409EFF;
    }
</style>
<style lang="less">
    
    
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .el-dialog__headerbtn i { background:white;font-size: 20px;  }
</style>